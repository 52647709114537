import Main from '../components/Main/Main';
import { lazy, useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addStartData,
  setDealsCounts,
  setDealsJob,
  setToken,
  setTokenExpires,
  setApproveUser,
  setOpenOrderId,
  setProposeOrderData,
  setProposeOrderId,
  setRefusalOrderId,
} from '../store/rootState';
import { useSearchParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ChatFilesLoader from '../components/ChatFilesLoader/ChatFilesLoader';
import CustomLoader from '../components/CustomLoader/CustomLoader';
import AuctionOrderOnfo from '../components/AuctionOrderInfo/AuctionOrderInfo';
import AuctionProposeOrder from '../components/AuctionProposeOrder/AuctionProposeOrder';
import AuctionRefusalReason from '../components/AuctionRefusalReason/AuctionRefusalReason';
import { SEND_DATA_TO_PARENT_WINDOW } from '../utils/utils';
import { settings } from '../utilsData';

const Header = lazy(() => import('../components/Header/Header'));
const Footer = lazy(() => import('../components/Footer/Footer'));
const ModalWrapper = lazy(
  () => import('../components/ModalWrapper/ModalWrapper')
);
const UploadedFiles = lazy(
  () => import('../components/UploadedFiles/UploadedFiles')
);
const QualificationFiles = lazy(
  () => import('../components/QulificationFiles/QulificationFiles')
);

const Root = () => {
  const dispatch = useDispatch();
  const [sidebarState, setSidebarState] = useState<any>(true);
  // Поставил по deafult false так как, сейчас интересен только аукцион
  const [isIframe, setIsIframe] = useState<any>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [auctionOrderModal, setAuctionOrderModal] = useState<any>(null);
  const [auctionProposalModal, setAuctionProposalModal] = useState<any>(null);
  const [auctionRefusalModal, setAuctionRefusalModal] = useState<any>(null);

  const orderId = useSelector((state: any) => state.root.openOrderId);
  const proposalId = useSelector((state: any) => state.root.proposeOrderId);
  const refusalId = useSelector((state: any) => state.root.refusalOrderId);
  const [tokenSet, setTokenSet] = useState(false);

  // Убираю срабатывание событий DND для всего документа
  document.addEventListener('dragover', (e) => e.preventDefault());
  document.addEventListener('drop', (e) => e.preventDefault());

  const [createdRef, setCreatedRed] = useState<any>(null);
  const updateRef = (value: any) => setCreatedRed(value);
  // Записываю в sessionstorage состяние открытия и закрытия меню

  const handleOpenerSidebar = () => {
    setSidebarState(!sidebarState);
    let sidebarStateCopy = String(!sidebarState);
    sessionStorage.setItem('sessionSidebarState', sidebarStateCopy);
  };

  // Загружаю инфу по профилю
  const loadProfileInitDataResult = (value: any) => {
    let startData: any = {};
    startData.balance = value.data.data.balance;
    startData.birthdate = value.data.data.birthdate;
    startData.city = value.data.data.city;
    startData.create = value.data.data.create;
    startData.email = value.data.data.email;
    startData.name = value.data.data.name;
    startData.phone = value.data.data.phone;
    dispatch(addStartData(startData));
  };

  // Загружаю инфу по кол-ву заказов по статусам в state
  const loadDealsCountInitDataResult = (value: any) => {
    dispatch(setDealsCounts(value.data.data.counts));
  };

  // Запрос с API списка задач (с доп.) по статусу
  const loadDealsByTypeResult = (value: any) => {
    let newObj = { counts: 0, cost: 0 };
    newObj.counts = value.data.data.list.length;
    value.data.data.list.map(
      (item: any) => (newObj.cost += Number(item.offer.price))
    );
    dispatch(setDealsJob(Object.assign({}, newObj)));
  };

  const loadFinanceDataResult = (value: any) => {
    //console.log(value);
  };

  // Главный токен приложения
  const setTokenApi = (data: any) => {
    if (!tokenSet && data && data.apiToken) {
      if (data.token && data.apiToken && data.tokenExpires) {
        dispatch(setToken({ token: data.apiToken }));
        dispatch(setTokenExpires({ expires: data.tokenExpires * 1000 }));
        setTokenSet(true);
      } // Message received from parent
    }
  };

  // Устаналиваю для redux значение клиента approve
  const setUserApprove = (data: any) => {
    if (data && data.approve !== undefined) {
      dispatch(setApproveUser(!!+data.approve));
    }
  };

  // Сдвигаю модальные окна для работы в iframe
  const openModalStyles = (data: any) => {
    let modalOrder =
      document.querySelector('.auction-propose-order') ??
      document.querySelector('.auction-refusal-reason');
    modalOrder
      ? (modalOrder.style.transform = `translate(-50%, calc(-50% ${
          data.headerHeight - data.footerHeight < 0
            ? '- ' + Math.abs(data.headerHeight - data.footerHeight) + 'px'
            : '+ ' + Math.abs(data.headerHeight - data.footerHeight) + 'px'
        }))`)
      : null;
  };

  // Закрываю все модальные окна по событию из ЛК
  const closeAllModals = () => {
    const allModals = document.querySelectorAll('.modal-wrapper');
    Array.from(allModals).map((item) => {
      item.classList.remove('open');
    });
    document.querySelector('body')!.classList.remove('modal');
    dispatch(setOpenOrderId({ openOrderId: null }));
    dispatch(setProposeOrderId({ proposeOrderId: null }));
    dispatch(setRefusalOrderId({ refusalOrderId: null }));
    dispatch(setProposeOrderData(null));
  };

  const handlerPostIframeMessage = (e: any) => {
    if (
      e &&
      e.data &&
      e.origin ===
        (import.meta.env.MODE === 'stage'
          ? settings.VITE_STAGE_URL
          : import.meta.env.MODE === 'master'
          ? settings.VITE_MASTER_URL || settings.VITE_MASTER_MIRROR2_URL || settings.VITE_MASTER_MIRROR_URL
          : 'http://wizard.local') && typeof e.data === "string"
    ) {
      let parsedData = JSON.parse(e?.data);
      if (parsedData) {
        setTokenApi(parsedData);
        setUserApprove(parsedData);

        if (
          parsedData.sidebarWidth &&
          parsedData.headerHeight &&
          parsedData.footerHeight
        ) {
          openModalStyles(parsedData);
        }

        if (parsedData.type === 'modal' && parsedData.eventType === 'close') {
          closeAllModals();
        }
      }
    }
  };

  useEffect(() => {
    const iframeParam: any = searchParams.get('iframe');
    /* iframeParam === 'true' ? setIsIframe(false) : setIsIframe(true); */

    let sessionSidebarState = sessionStorage.getItem('sessionSidebarState');
    if (sessionSidebarState) {
      setSidebarState(sessionSidebarState === 'true' ? true : false);
    }
    /*     loadProfileInitData(token, loadProfileInitDataResult);
    loadDealsCountInitData(token, loadDealsCountInitDataResult);
    loadDealsByType(token, 'job', loadDealsByTypeResult);

    loadFinanceData(
      token,
      '2017-01-01 00:00:00',
      '2022-10-11 23:59:59',
      234346,
      'return_success',
      loadFinanceDataResult
    ); */

    window.addEventListener('message', (e) => handlerPostIframeMessage(e));
    return () =>
      window.removeEventListener('message', (e) => handlerPostIframeMessage(e));
  }, []);

  useEffect(() => {
    if (orderId) {
      SEND_DATA_TO_PARENT_WINDOW({ type: 'modal', eventType: 'open' });
      setAuctionOrderModal(true);
    } else {
      SEND_DATA_TO_PARENT_WINDOW({ type: 'modal', eventType: 'close' });
      setAuctionOrderModal(false);
    }
  }, [orderId]);

  useEffect(() => {
    if (proposalId) {
      SEND_DATA_TO_PARENT_WINDOW({ type: 'modal', eventType: 'open' });
      setAuctionProposalModal(true);
    } else {
      SEND_DATA_TO_PARENT_WINDOW({ type: 'modal', eventType: 'close' });
      setAuctionProposalModal(false);
    }
  }, [proposalId]);

  useEffect(() => {
    if (refusalId) {
      SEND_DATA_TO_PARENT_WINDOW({ type: 'modal', eventType: 'open' });
      setAuctionRefusalModal(true);
    } else {
      SEND_DATA_TO_PARENT_WINDOW({ type: 'modal', eventType: 'close' });
      setAuctionRefusalModal(false);
    }
  }, [refusalId]);

  return (
    <>
      {/*       {isIframe ? (
        <Suspense
          fallback={<CustomLoader width={100} height={100}></CustomLoader>}
        >
          <Header
            sidebarState={sidebarState}
            handleOpenerSidebar={handleOpenerSidebar}
          />
        </Suspense>
      ) : null} */}
      <Main
        sidebarState={sidebarState}
        handleOpenerSidebar={handleOpenerSidebar}
        isIframe={isIframe}
      />
      <ToastContainer limit={3} />
      {/*       {isIframe ? (
        <Suspense
          fallback={<CustomLoader width={100} height={100}></CustomLoader>}
        >
          <Footer type="main" />
        </Suspense>
      ) : null} */}
      <Suspense
        fallback={<CustomLoader width={100} height={100}></CustomLoader>}
      >
        <ModalWrapper
          type="uploaded"
          updateRef={updateRef}
          component={<UploadedFiles createdRef={createdRef} />}
        />
      </Suspense>
      <Suspense
        fallback={<CustomLoader width={100} height={100}></CustomLoader>}
      >
        <ModalWrapper
          type="qulification"
          updateRef={updateRef}
          component={<QualificationFiles createdRef={createdRef} />}
        />
      </Suspense>
      <Suspense
        fallback={<CustomLoader width={100} height={100}></CustomLoader>}
      >
        <ModalWrapper
          type="filesToChat"
          updateRef={updateRef}
          component={<ChatFilesLoader createdRef={createdRef} />}
        />
      </Suspense>
      {auctionOrderModal ? (
        <Suspense
          fallback={<CustomLoader width={100} height={100}></CustomLoader>}
        >
          <ModalWrapper
            type="auction-order-info"
            updateRef={updateRef}
            component={<AuctionOrderOnfo createdRef={createdRef} />}
          />
        </Suspense>
      ) : null}
      {auctionProposalModal ? (
        <Suspense
          fallback={<CustomLoader width={100} height={100}></CustomLoader>}
        >
          <ModalWrapper
            type="auction-proposal-order"
            updateRef={updateRef}
            component={<AuctionProposeOrder createdRef={createdRef} />}
          />
        </Suspense>
      ) : null}
      {auctionRefusalModal ? (
        <Suspense
          fallback={<CustomLoader width={100} height={100}></CustomLoader>}
        >
          <ModalWrapper
            type="auction-refusal-reason"
            updateRef={updateRef}
            component={<AuctionRefusalReason createdRef={createdRef} />}
          />
        </Suspense>
      ) : null}
    </>
  );
};

export default Root;
