import './ErrorPage.scss';
import { NavLink, useRouteError } from 'react-router-dom';

const ErrorPage = () => {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div id="error-page" className="error-page">
      <h1>Возникла ошибка!</h1>
      <p>
        Приносим свои извинения, возникла ошибка по нашей вине. Попробуйте
        перезагрузить страницу или связаться с нами по телефону{' '}
        <a href="tel:+78005517871" className="error-page__phone">
          8 (800) 551-78-71
        </a>
      </p>
      <NavLink to="/auction" className="error-page__link-to-main">
        На главную
      </NavLink>
      {/* <p>
        <i>Error: {error.statusText || error.message}</i>
      </p> */}
    </div>
  );
};

export default ErrorPage;
