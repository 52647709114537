import './Breadcrumbs.scss';
import { useLocation, NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ROUTES_LIST, COUNT_MONTH } from '../../utilsData';

const Breadcrumbs = () => {
  let location = useLocation();
  const [pathList, setPathList] = useState([['Главная', '/']]);
  let chosenDate = new Date(
    Number(
      location.pathname.split('/')[location.pathname.split('/').length - 1]
    )
  );

  useEffect(() => {
    let startLocation = decodeURIComponent(location.pathname)
      .split('/')
      .filter((item) => item !== '');
    let newPathList = [['Главная', '/']];
    startLocation.map((item: string, index: number) => {
      newPathList.push([
        item[0] === '№'
          ? 'Заказ ' + item
          : item.length === 6 && !isNaN(parseInt(item, 10))
          ? 'Заказ №' + item
          : !isNaN(new Date(Number(item)))
          ? new Date(Number(item)).getDate() +
            ' ' +
            COUNT_MONTH[new Date(Number(item)).getMonth()] +
            ' ' +
            new Date(Number(item)).getFullYear() +
            'г.'
          : ROUTES_LIST[item]
          ? ROUTES_LIST[item]
          : item,
        newPathList.length === 1 || newPathList.length === 0
          ? '/' + item
          : newPathList.length >= 2
          ? newPathList
              .reduce((prev: any, next: any) => prev[1] + next[1])
              .slice(1) +
            '/' +
            item
          : item,
      ]);
    });
    setPathList(newPathList);
    chosenDate = new Date(
      Number(
        location.pathname.split('/')[location.pathname.split('/').length - 1]
      )
    );
  }, [location]);

  return (
    <div className="breadcrumbs">
      <ul className="breadcrumbs__list">
        {pathList.map((item, index: any) => (
          <li className="breadcrumbs__item" key={index}>
            <NavLink
              to={
                item[1] === '/deals/ondate'
                  ? '/deals'
                  : item[1] === '/stats'
                  ? '/stats/statsdeals'
                  : item[1]
              }
              className="breadcrumbs__link"
            >
              {item[0]}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Breadcrumbs;
