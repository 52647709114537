import { Console } from 'console';
import { createContext, useState, useLayoutEffect, useEffect } from 'react';

export const ThemeContext = createContext({
  isDarkTheme: false,
  toggleTheme: () => {},
});

export const ThemeProvider = ({ children }: any) => {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const mq = window.matchMedia('(prefers-color-scheme: dark)');

  const setLightTheme = () => {
    document.documentElement.classList.remove('dark-mode');
    document.documentElement.classList.add('light-mode');
  };

  const setDarkTheme = () => {
    document.documentElement.classList.remove('light-mode');
    document.documentElement.classList.add('dark-mode');
  };

  const toggleTheme = () => {
    if (!isDarkTheme) {
      setDarkTheme();
      localStorage.setItem('themeColor', 'dark');
    } else {
      setLightTheme();
      localStorage.setItem('themeColor', 'light');
    }
    setIsDarkTheme(!isDarkTheme);
  };

  useEffect(() => {
    const cookiesDarkTheme = localStorage.getItem('themeColor');

    if (cookiesDarkTheme && cookiesDarkTheme === 'light') {
      setLightTheme();
      setIsDarkTheme(false);
    } else if (cookiesDarkTheme && cookiesDarkTheme === 'dark') {
      setDarkTheme();
      setIsDarkTheme(true);
    } else {
      if (mq.matches) {
        setDarkTheme();
        setIsDarkTheme(true);
      } else {
        setLightTheme();
        setIsDarkTheme(false);
      }
    }
    // Пока по дефолту так. УБРАТЬ!
    setLightTheme();
        setIsDarkTheme(false);
  }, []);

  return (
    <ThemeContext.Provider value={{ isDarkTheme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
