const Picture = ({
  className,
  path,
  image,
  format,
  width,
  height,
  altText,
}) => {
  return (
    <picture className={className}>
      <source
        type={'image/webp'}
        srcSet={
          '../' +
          path +
          '/' +
          image +
          '.webp' +
          ', ' +
          '../' +
          path +
          '/' +
          image +
          '@2x.webp' +
          ' 2x'
        }
      />
      <img
        src={'../' + path + '/' + image + '.' + format}
        srcSet={'../' + path + '/' + image + '@2x.' + format + ' 2x'}
        width={width}
        height={height}
        alt={altText}
      />
    </picture>
  );
};

function debounce(func, wait = 100) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

const SEND_DATA_TO_PARENT_WINDOW = (data) => {
  window.parent.postMessage(data, '*');
};

const removeSpaces = (string) => string.toString().replace(/ /g, '');

const splitNumberIntoDigits = (number) =>
  number
    .toString()
    .replace(/ /g, '')
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

function isEmpty(obj) {
  for (let key in obj) {
    // если тело цикла начнет выполняться - значит в объекте есть свойства
    return false;
  }
  return true;
}

const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

const getCookie = (name) => {
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

const checkTokenExpiresDate = (date) => {
  const today = new Date().getTime();
  if (today > date) {
    return false;
  }
  return true;
};

export {
  Picture,
  debounce,
  isEmpty,
  splitNumberIntoDigits,
  removeSpaces,
  SEND_DATA_TO_PARENT_WINDOW,
  setCookie,
  getCookie,
  checkTokenExpiresDate,
};
