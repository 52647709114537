const menuList = [
  {
    name: 'Мои заказы',
    icon: 'DocsIcon',
    count: '',
    type: 'count',
    to: 'deals/',
    list: [
      'В работе',
      'Просроченные',
      'В корректировке',
      'На проверке',
      'Ненужные',
      'Завершенные',
    ],
  },
  {
    name: 'Сообщения',
    icon: 'MessageIcon',
    count: 22,
    type: 'count',
    to: 'messages/',
    list: ['По новым заказам', 'По моим заказам'],
  },
  {
    name: 'Аукцион',
    icon: 'StorageIcon',
    count: 6,
    type: 'count',
    to: 'auction/',
    secondName: 'Лента заказов',
    list: [
      'Аукцион',
      'Мои ставки',
      'В корректировке',
      'Избранное',
      'Мои отказы',
    ],
  },
  {
    name: 'Статистика',
    icon: 'ChartIcon',
    type: 'selector',
    to: 'stats/',
    list: ['Статистика', 'Рейтинг'],
    secondList: [
      { name: 'Статистика заказов', to: '/stats/statsdeals/' },
      { name: 'Рейтинг', to: '/stats/rating/' },
    ],
  },
  {
    name: 'Финансы',
    icon: 'CardIcon',
    type: 'default',
    to: 'finance/',
    list: ['История операций', 'Выплаты'],
  },
  {
    name: 'Справочная информация',
    icon: 'HelpIcon',
    type: 'selector',
    to: 'helpInfo/',
    list: ['Новости', 'Вопрос-ответ', 'Справочник', 'Контакты', 'Оферта'],
  },
  {
    name: 'Обучение',
    icon: 'FileIcon',
    type: 'selector',
    to: 'studing/',
    list: ['Обучение', 'Видеоинструкция'],
  },
];

const DataSubjects = [
  {
    id: '380',
    name: 'Экология',
    priority: '0',
    active: '1',
    category: 'Гуманитарное',
    price_category: '0',
  },
  {
    id: '527',
    name: 'Эконометрика',
    priority: '0',
    active: '1',
    category: 'Естественное',
    price_category: '0',
  },
  {
    id: '818',
    name: 'Экономика отрасли',
    priority: '0',
    active: '1',
    category: 'Экономическое',
    price_category: '0',
  },
  {
    id: '153',
    name: 'Экономическое право',
    priority: '0',
    active: '1',
    category: 'Экономическое',
    price_category: '0',
  },
  {
    id: '379',
    name: 'Экологическое право',
    priority: '0',
    active: '1',
    category: 'Экономическое',
    price_category: '0',
  },
  {
    id: '320',
    name: 'Экономическая география',
    priority: '0',
    active: '1',
    category: 'Компьютерное',
    price_category: '0',
  },
  {
    id: '529',
    name: 'Экономическая статистика',
    priority: '0',
    active: '1',
    category: 'Естественное',
    price_category: '0',
  },
  {
    id: '816',
    name: 'Экономика народного хозяйства',
    priority: '0',
    active: '1',
    category: 'Экономическое',
    price_category: '0',
  },
  {
    id: '820',
    name: 'Экономика сельского хозяйства',
    priority: '0',
    active: '1',
    category: 'Экономическое',
    price_category: '0',
  },
  {
    id: '814',
    name: 'Экономика городского хозяйства',
    priority: '0',
    active: '1',
    category: 'Экономическое',
    price_category: '0',
  },
  {
    id: '528',
    name: 'Экономико-математическое моделирование',
    priority: '0',
    active: '1',
    category: 'Естественное',
    price_category: '0',
  },
  {
    id: '774',
    name: 'Внешнеэкономическая деятельность (ВЭД)',
    priority: '0',
    active: '1',
    category: 'Экономическое',
    price_category: '0',
  },
  {
    id: '299',
    name: 'Геоэкология',
    priority: '0',
    active: '1',
    category: 'Естественное',
    price_category: '0',
  },
  {
    id: '808',
    name: 'Государственное регулирование экономики',
    priority: '0',
    active: '1',
    category: 'Экономическое',
    price_category: '0',
  },
  {
    id: '809',
    name: 'Институциональная экономика',
    priority: '0',
    active: '1',
    category: 'Экономическое',
    price_category: '0',
  },
  {
    id: '400',
    name: 'Информатика экономическая',
    priority: '0',
    active: '1',
    category: 'Техническое',
    price_category: '0',
  },
  {
    id: '811',
    name: 'Макроэкономика',
    priority: '0',
    active: '1',
    category: 'Экономическое',
    price_category: '0',
  },
  {
    id: '502',
    name: 'Математические методы в экономике',
    priority: '0',
    active: '1',
    category: 'Техническое',
    price_category: '0',
  },
  {
    id: '756',
    name: 'Международные экономические отношения',
    priority: '0',
    active: '1',
    category: 'Техническое',
    price_category: '0',
  },
  {
    id: '812',
    name: 'Микроэкономика',
    priority: '0',
    active: '1',
    category: 'Экономическое',
    price_category: '0',
  },
  {
    id: '776',
    name: 'Мировая экономика',
    priority: '0',
    active: '1',
    category: 'Техническое',
    price_category: '0',
  },
  {
    id: '372',
    name: 'Основы экологического природопользования',
    priority: '0',
    active: '1',
    category: 'Гуманитарное',
    price_category: '0',
  },
  {
    id: '813',
    name: 'Региональная экономика',
    priority: '0',
    active: '1',
    category: 'Экономическое',
    price_category: '0',
  },
  {
    id: '778',
    name: 'Рыночная экономика',
    priority: '0',
    active: '1',
    category: 'Экономическое',
    price_category: '0',
  },
  {
    id: '110',
    name: 'Социально-экономические и политические процессы',
    priority: '0',
    active: '1',
    category: 'Техническое',
    price_category: '0',
  },
];
const auctionList = [
  {
    number: '№196661',
    workType: 'Контрольная работа',
    type: 'В поиске автора',
    subject: 'Русская литература:',
    theme: '“Сопоставительный анализ двух экранизаций Ревизор”',
    deliveryDate: 1621705651000,
    original: '80% antiplagiat.ru ВУЗ',
    files: 3,
  },
  {
    number: '№196644',
    workType: 'Контрольная работа',
    type: 'В поиске автора',
    subject: 'Гражданское право:',
    theme:
      '“Правоспособность юридического лица и ее виды по российскому законодательству.”',
    deliveryDate: 1664009410111,
    original: '60% Etxt',
    checked: 2,
  },
  {
    number: '№196608',
    workType: 'Курсовая работа',
    type: 'В поиске автора',
    subject: 'Педагогика:',
    theme:
      '“Формирование грамматического строя речи младших дошкольников с использованием речевых заданий и упражнений.”',
    deliveryDate: 1633880671000,
    original: '80% antiplagiat.ru ВУЗ',
    files: 1,
    checked: 4,
  },
  {
    number: '№196600',
    workType: 'Контрольная работа',
    type: 'В поиске автора',
    subject: 'Менеджмент:',
    theme: '“Методы нормирования, планирования и контроля реализации проектов”',
    deliveryDate: 1633880671000,
    original: '80% antiplagiat.ru ВУЗ',
    files: 3,
  },
];

const listReviews = [
  {
    id: 162897,
    time: '08.08.2022 14:23',
    mark: 5,
    text: 'Благодарю автора за контрольную работу, написанную во время и четко в тему.',
  },
  {
    id: 281958,
    time: '26.07.2022 18:01',
    mark: 5,
    text: 'Спасибо большое за помощь',
  },
  {
    id: 278580,
    time: '09.06.2022 15:31',
    mark: 4,
    text: 'Работу зачёл преподаватель, но мне не понравилось введение, маленькое и не раскрыта актуальность, можно было и больше, а также был абзац, где абсолютно в каждом предложении повторялось слово ВСЕГДА, это было сделано для массовки? Даже если и да, то смотрелось это не очень профессионально, а также курсовая наполненная, текста много, большего от неё и не нужно',
  },
  {
    id: 162897,
    time: '08.08.2022 14:23',
    mark: 5,
    text: 'Благодарю автора за контрольную работу, написанную во время и четко в тему.',
  },
  {
    id: 281958,
    time: '26.07.2022 18:01',
    mark: 5,
    text: 'Спасибо большое за помощь',
  },
  {
    id: 278580,
    time: '09.06.2022 15:31',
    mark: 4,
    text: 'Работу зачёл преподаватель, но мне не понравилось введение, маленькое и не раскрыта актуальность, можно было и больше, а также был абзац, где абсолютно в каждом предложении повторялось слово ВСЕГДА, это было сделано для массовки? Даже если и да, то смотрелось это не очень профессионально, а также курсовая наполненная, текста много, большего от неё и не нужно',
  },
];

const listMock = [
  { name: 'Завершено', value: 12, type: 'finished' },
  { name: 'В работе', value: 1, type: 'work' },
  { name: 'В корректировке', value: 1, type: 'rework' },
  { name: 'Просроченно', value: 0, type: 'late' },
  { name: 'На проверке', value: 0, type: 'check' },
  { name: 'Неактуально', value: 0, type: 'end' },
];

const listMockFinance = [
  { name: 'Вывод средств', value: 'out', to: '/' },
  {
    name: 'История операций',
    value: 'historyOperation',
    to: '/historyOperation',
  },
  { name: 'История транзакций', value: 'historyTran', to: '/historyTran' },
];

const sortAuctions = [
  { name: 'Аукцион', title: 'AUCTION', count: 4 },
  { name: 'Мои ставки', title: 'MY_BETS', count: 1 },
  { name: 'Мои отказы', title: 'MY_REFUSALS', count: 1 },
  { name: 'Избранное', title: 'FAVOURITES', count: 0 },
];

const sortProfile = [
  { name: 'Личные данные', link: '/profile' },
  { name: 'Квалификация', link: '/profile/qualification' },
  { name: 'Аукцион', link: '/profile/auctionSettings' },
  { name: 'Реквизиты', link: '/profile/requisites' },
  { name: 'Уведомления', link: '/profile/notifications' },
];

const contactsFormInitial = {
  profileEmail: 'maeremin@mail.ru',
  profileTel: '+7 921-952-31-12',
  profileSecondTel: '+7 ',
  profileTelegram: '@',
  profileTypePay: 'men',
};

const personalInfoInitial = {
  profileName: 'Михаил',
  profileSecondName: 'Александрович',
  profilePatronymic: 'ЕриминTest',
  profileCity: 'Cанкт-Петербург',
  profileBirtDate: 504869953000,
};

const degreeInitial = {
  academicDegree: 'doctor',
  academicName: 'professor',
  dissertationTopic: '',
  industry: '',
};

const educationInitial = {
  institution: 'ФИНЭК',
  faculty: 'Менеджмента',
  speciality: 'Общий и стратегический менед',
  status: 'graduate',
  yearGraduated: 2009,
};

const token = 'a354416a-5878-4cba-aa75-d7f87d03e16b';

const settingsListWorkTypes = [
  {
    text: 'Реферат',
    checked: false,
    id: 'referat',
  },
  {
    text: 'Тезисный план',
    checked: false,
    id: 'referat',
  },
  {
    text: 'Ответы на билеты',
    checked: false,
    id: 'referat',
  },
  {
    text: 'Эссе ',
    checked: true,
    id: 'referat',
  },
  {
    text: 'Презентация',
    checked: false,
    id: 'referat',
  },
  {
    text: 'Решение задач',
    checked: false,
    id: 'referat',
  },
  {
    text: 'Перевод',
    checked: false,
    id: 'referat',
  },
  {
    text: 'Контрольная работа',
    checked: false,
    id: 'referat',
  },
  {
    text: 'Компьютерный набор текста',
    checked: false,
    id: 'referat',
  },
  {
    text: 'Доклад',
    checked: false,
    id: 'referat',
  },
  {
    text: 'Онлайн помощь',
    checked: false,
    id: 'referat',
  },
  {
    text: 'Речь к диплому',
    checked: false,
    id: 'referat',
  },
  {
    text: 'Диаграммы, таблицы',
    checked: false,
    id: 'referat',
  },
  {
    text: 'Доработка заказа клиента',
    checked: false,
    id: 'referat',
  },
  {
    text: 'Статья',
    checked: false,
    id: 'referat',
  },
  {
    text: 'Лабораторная работа',
    checked: false,
    id: 'referat',
  },
  {
    text: 'План',
    checked: false,
    id: 'referat',
  },
];

const settingsListWorkTypesAverage = [
  {
    text: 'Курсовая работа',
    checked: false,
    id: 'dddf',
  },
  {
    text: 'Отчет по практике',
    checked: false,
    id: 'dddf',
  },
  {
    text: 'Чертеж от руки',
    checked: false,
    id: 'dddf',
  },
  {
    text: 'Чертёж',
    checked: true,
    id: 'dddf',
  },
  {
    text: 'Бизнес план',
    checked: false,
    id: 'dddf',
  },
];

const settingsListWorkTypesHard = [
  {
    text: 'Рецензия',
    checked: false,
    id: 'dddf',
  },
  {
    text: 'Глава диплома',
    checked: false,
    id: 'dddf',
  },
  {
    text: 'Дипломная работа MBA',
    checked: false,
    id: 'dddf',
  },
  {
    text: 'Аспирантский реферат',
    checked: true,
    id: 'dddf',
  },
  {
    text: 'ВАК',
    checked: false,
    id: 'dddf',
  },
  {
    text: 'Научная статья',
    checked: false,
    id: 'dddf',
  },
  {
    text: 'ВКР (Выпускная квалификационная работа)',
    checked: false,
    id: 'dddf',
  },
  {
    text: 'Диссертация',
    checked: false,
    id: 'dddf',
  },
  {
    text: 'Магистерская работа',
    checked: false,
    id: 'dddf',
  },
  {
    text: 'Дипломная работа',
    checked: false,
    id: 'dddf',
  },
  {
    text: 'Монография',
    checked: false,
    id: 'dddf',
  },
  {
    text: 'Научно-исследовательская работа',
    checked: false,
    id: 'dddf',
  },
];

const settingsListAcademicSubject = [
  {
    text: 'Архивоведение',
    id: 'referat',
  },
  {
    text: 'Библиотековедение',
    id: 'referat',
  },
  {
    text: 'Журналистика',
    id: 'referat',
  },
  {
    text: 'Журналистика',
    id: 'referat',
  },
  {
    text: 'Искусство',
    id: 'referat',
  },
  {
    text: 'История',
    id: 'referat',
  },
  {
    text: 'Культура',
    id: 'referat',
  },
  {
    text: 'Лингвистика',
    id: 'referat',
  },
  {
    text: 'Литература',
    id: 'referat',
  },
  {
    text: 'Международные Отношения',
    id: 'referat',
  },
  {
    text: 'Педагогика',
    id: 'referat',
  },
  {
    text: 'Политология',
    id: 'referat',
  },
  {
    text: 'Право',
    id: 'referat',
  },
  {
    text: 'Реклама и PR',
    id: 'referat',
  },
  {
    text: 'Религиоведение',
    id: 'referat',
  },
  {
    text: 'Социология',
    id: 'referat',
  },
  {
    text: 'Туризм',
    id: 'referat',
  },
  {
    text: 'Философия',
    id: 'referat',
  },
  {
    text: 'Языки',
    id: 'referat',
  },
  {
    text: 'Языкознание',
    id: 'referat',
  },
];

const subjectList = [
  {
    text: 'Военная педагогика',
    checked: false,
    id: 'rr',
  },
  {
    text: 'Дефектология',
    checked: false,
    id: 'rr',
  },
  {
    text: 'Дошкольная педагогика',
    checked: false,
    id: 'rr',
  },
  {
    text: 'Игровые методики',
    checked: true,
    id: 'rr',
  },
  {
    text: 'Коррекционная педагогика',
    checked: false,
    id: 'rr',
  },
  {
    text: 'Логопедия',
    checked: false,
    id: 'rr',
  },
  {
    text: 'Педагогика',
    checked: false,
    id: 'rr',
  },
  {
    text: 'Педагогика (другое)',
    checked: false,
    id: 'rr',
  },
  {
    text: 'Развитие речи',
    checked: false,
    id: 'rr',
  },
  {
    text: 'Социальная педагогика',
    checked: false,
    id: 'rr',
  },
  {
    text: 'Сурдопедагогика',
    checked: false,
    id: 'rr',
  },
  {
    text: 'Теория и методология обучения и воспитания',
    checked: false,
    id: 'rr',
  },
  {
    text: 'Тифлопедагогика',
    checked: false,
    id: 'rr',
  },
  {
    text: 'Управление дошкольным образованием',
    checked: false,
    id: 'rr',
  },
];

const languageList = [
  {
    text: 'Русский',
    checked: true,
  },
  {
    text: 'Белорусский',
    checked: false,
  },
  {
    text: 'Казахский',
    checked: false,
  },
  {
    text: 'Английский',
    checked: false,
  },
  {
    text: 'Английский',
    checked: false,
  },
  {
    text: 'Французский',
    checked: false,
  },
  {
    text: 'Итальянский',
    checked: false,
  },
  {
    text: 'Испанский',
    checked: false,
  },
];

const sortNotification = [
  { name: 'Все' },
  { name: 'Заказы в работе' },
  { name: 'Аукцион' },
  { name: 'Приглашения' },
  { name: 'Новости' },
];

const sortMessages = [
  {
    name: 'Все',
    count: 12,
  },
  {
    name: 'Мои заказы',
    count: 6,
  },
  {
    name: 'В аукционе',
    count: 6,
  },
];

const messagesDataMock = [
  {
    id: 1,
    date: '26.04.2023 19:00',
    itemType: 'messages',
    orderNumber: '196661',
    orderType: 'Контрольная работа',
    orderStatus: 'В поиске автора',
    orderSubject: 'Русская литература',
    orderTheme: 'Сопоставительный анализ двух экранизаций Ревизор',
    unreadMessagesCount: 1,
    messageType: 'text',
    fileName: undefined,
    fileSize: undefined,
    lastUnreadMessage:
      'Добрый день! Выручите, пожалуйста, со срочным заказом на 1-е мая! Сможете?',
  },
  {
    id: 2,
    date: '26.04.2023 16:11',
    itemType: 'messages',
    orderNumber: '196661',
    orderType: 'Контрольная работа',
    orderStatus: 'В поиске автора',
    orderSubject: 'Педагогика',
    orderTheme:
      'Формирование грамматического строя речи младших дошкольников с использованием речевых заданий и упражнений.',
    unreadMessagesCount: 2,
    messageType: 'file',
    fileName: 'bez_imeni.pdf',
    fileSize: '40',
    lastUnreadMessage: undefined,
  },
  {
    id: 3,
    date: '25.04.2023 02:15',
    itemType: 'messages',
    orderNumber: '353847',
    orderType: 'Курсовая работа',
    orderStatus: 'Выполняется автором',
    orderSubject: 'Транспорт',
    orderTheme:
      'Вкратце описать должностные обязанности Контролера ТС ТрС АТ, можно приложить должностную инструкцию. Каким документом (например, приказом) назначается контролер. Требования к нему (см. приказ Минтранса № 282). Провести предрейсовый осмотр (можно по листу проверки) любого автомобиля.',
    unreadMessagesCount: 4,
    messageType: 'text',
    fileName: undefined,
    fileSize: undefined,
    lastUnreadMessage:
      'Здравствуйте! С такой работой сможете помочь? У клиента Итоговая квалификационная работа (ИКР)не совсем понимаем, склоняется ли она ближе к вкр или курсовой ',
  },
];

const notificationsSettings = [
  {
    text: 'Уведомления об изменении статуса заказа',
    checked: false,
    id: 'orders-changes',
  },
  {
    text: 'Уведомления о назначенных выплатах',
    checked: false,
    id: 'pays',
  },
  {
    text: 'Всплывающие уведомления',
    checked: false,
    id: 'up-notification',
  },
  {
    text: 'Новости',
    checked: false,
    id: 'news',
  },
  {
    text: 'Сообщения от кураторов (E-mail)',
    checked: false,
    id: 'messages-curators',
  },
  {
    text: 'Ежедневная подборка заказов (E-mail)',
    checked: false,
    id: 'everyday-orders',
  },
  {
    text: 'Новостные рассылки (E-mail)',
    checked: false,
    id: 'news-email',
  },
  {
    text: 'Дублировать важные уведомления в Телеграм',
    checked: false,
    id: 'double',
  },
];

export {
  menuList,
  auctionList,
  listReviews,
  sortNotification,
  listMock,
  listMockFinance,
  sortAuctions,
  sortProfile,
  contactsFormInitial,
  personalInfoInitial,
  degreeInitial,
  educationInitial,
  token,
  settingsListWorkTypes,
  settingsListWorkTypesAverage,
  settingsListWorkTypesHard,
  settingsListAcademicSubject,
  subjectList,
  languageList,
  sortMessages,
  messagesDataMock,
  notificationsSettings,
  DataSubjects,
};
