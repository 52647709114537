import axios from 'axios';
import { settings } from '../utilsData';

export const apiClient = axios.create({
  baseURL:
    import.meta.env.MODE === 'stage'
      ? settings.VITE_API_STAGE_URL
      : import.meta.env.MODE === 'master'
      ? settings.VITE_API_MASTER_URL
      : 'https://stage.v2.wizard24.ru/api',
  /*   withCredentials: true, // required to handle the CSRF token */
});

export default {
  login(payload) {
    return apiClient.post('/login', payload ? payload : null);
  },
  refresh(token) {
    return apiClient.post('/refresh', token ? token : null, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  logout(payload) {
    return apiClient.post('/logout', payload ? payload : null, {
      headers: { Authorization: `Bearer ${payload.token}` },
    });
  },
  authByToken(token, payload, signal) {
    return apiClient.post('/authByToken', payload ? payload : null, {
      headers: { Authorization: `Bearer ${token}` },
      signal,
    });
  },
  ordersList(token, payload, signal) {
    return apiClient.post('/orders/list', payload ? payload : null, {
      headers: { Authorization: `Bearer ${token}` },
      signal,
    });
  },
  ordersFilters(token, payload, signal) {
    return apiClient.post('/orders/filters', payload ? payload : null, {
      headers: { Authorization: `Bearer ${token}` },
      signal,
    });
  },
  profile(token) {
    return apiClient.get('/profile/get', {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  orderEventSet(token, payload, id) {
    return apiClient.post(`/order/${id}/set`, payload ? payload : null, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  orderEventHide(token, payload, id) {
    return apiClient.post(`/order/${id}/hide`, payload ? payload : null, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  orderEventFavorite(token, payload, id) {
    return apiClient.post(`/order/${id}/favorite`, payload ? payload : null, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};
