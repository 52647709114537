import './ItemCommonInfo.scss';
import { ReactComponent as CalendarIcon } from '../../assets/img/calendar.svg';
import { ReactComponent as AplhIcon } from '../../assets/img/alph.svg';
import { ReactComponent as AttentionIcon } from '../../assets/img/attention.svg';
import { ReactComponent as PagesIcon } from '../../assets/img/pages.svg';
import { ReactComponent as FontIcon } from '../../assets/img/font.svg';
import { ReactComponent as LineHeightIcon } from '../../assets/img/lineHeight.svg';
import { useLoaderData } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { MONTH } from '../../utilsData';
import CustomLoader from '../CustomLoader/CustomLoader';
import { token } from '../../mock';
import { loadDealById } from '../../utils/utilsApi';

export async function loader(loaderData: { params: any }) {
  return loaderData.params.id;
}

const ItemCommonInfo = ({ customId, type }: any) => {
  const [dataItem, setDataItem] = useState<any>(false);
  const [visualDateState, setVisualDateState] = useState();
  const [dealAlertType, setDealAlertType] = useState<any>(false);

  const id: any = customId ? customId : useLoaderData() ? useLoaderData() : 1;
  const loadedByIdResultFunc = (value: any) => {
    if (value.data.status === true) {
      setDataItem(Object.assign({}, value.data.data.entry));
    } else {
      setDataItem(false);
    }
  };

  useEffect(() => {
    id[0] === '№'
      ? loadDealById(
          token,
          Number(id.slice(1, id.length)),
          loadedByIdResultFunc
        )
      : loadDealById(token, Number(id), loadedByIdResultFunc);
  }, [id]);

  const dateNow = Date.now();
  let alertDate: any;
  let visualDate: any;

  useEffect(() => {
    if (dataItem) {
      const dateDelivery = dataItem.offer.deadline * 1000;
      const newDateDelivery = new Date(dateDelivery);

      if (dateDelivery - dateNow < 0) {
        setDealAlertType('warning');
      } else if (
        dateDelivery - dateNow < 345600000 &&
        dateDelivery - dateNow > 0
      ) {
        setDealAlertType('attention');
      }
      visualDate =
        (newDateDelivery.getDate() < 10
          ? '0' + newDateDelivery.getDate()
          : newDateDelivery.getDate()) +
        '.' +
        MONTH[newDateDelivery.getMonth()] +
        '.' +
        newDateDelivery.getFullYear() +
        ' ' +
        (newDateDelivery.getHours() < 10
          ? '0' + newDateDelivery.getHours()
          : newDateDelivery.getHours()) +
        ':' +
        (newDateDelivery.getMinutes() < 10
          ? '0' + newDateDelivery.getMinutes()
          : newDateDelivery.getMinutes());
      setVisualDateState(visualDate);
    }
  }, [dataItem]);

  return (
    <div
      className={
        type ? `item-common-info item-common-info--${type}` : 'item-common-info'
      }
    >
      {dataItem ? (
        <>
          <span className="item-common-info__price">
            {dataItem.offer.price + '₽'}
          </span>
          <ul className="item-common-info__list-description">
            <li
              className={
                dealAlertType && dataItem.status_data.name !== 'Исполнен'
                  ? 'item-common-info__item item-common-info__item--delivery-date item-common-info__item--alert-date'
                  : 'item-common-info__item item-common-info__item--delivery-date'
              }
            >
              <span className="item-common-info__item-icon">
                <CalendarIcon />
              </span>
              <span className="item-common-info__item-description-name">
                Срок сдачи:
              </span>
              <span className="item-common-info__item-description-info">
                {alertDate ? alertDate : visualDateState}
              </span>
              {dealAlertType && dataItem.status_data.name !== 'Исполнен' ? (
                dealAlertType === 'warning' ? (
                  <span className="item-common-info__deal-info">
                    <AttentionIcon />
                    Просрочка
                  </span>
                ) : (
                  <span className="item-common-info__deal-info">
                    <AttentionIcon />
                    Скоро отдавать
                  </span>
                )
              ) : (
                ''
              )}
            </li>
            <li className="item-common-info__item item-common-info__item--original">
              <span className="item-common-info__item-icon">
                <AplhIcon />
              </span>
              <span className="item-common-info__item-description-name">
                Оригинальность:
              </span>
              <span className="item-common-info__item-description-info">
                {dataItem.originality_proc + ' ' + dataItem.originality_name}
              </span>
            </li>
            <li className="item-common-info__item item-common-info__item--pages">
              <span className="item-common-info__item-icon">
                <PagesIcon />
              </span>
              <span className="item-common-info__item-description-name">
                Страниц:
              </span>
              <span className="item-common-info__item-description-info">
                {dataItem.pages_count}
              </span>
            </li>
            <li className="item-common-info__item item-common-info__item--font">
              <span className="item-common-info__item-icon">
                <FontIcon />
              </span>
              <span className="item-common-info__item-description-name">
                Шрифт:
              </span>
              <span className="item-common-info__item-description-info">
                {dataItem.font_size_data.name}
              </span>
            </li>
            <li className="item-common-info__item item-common-info__item--line-height">
              <span className="item-common-info__item-icon">
                <LineHeightIcon />
              </span>
              <span className="item-common-info__item-description-name">
                Интервал:
              </span>
              <span className="item-common-info__item-description-info">
                {dataItem.font_interval_data.name}
              </span>
            </li>
          </ul>
          <p className="item-common-info__name-note">Задание:</p>
          <p className="item-common-info__note">{dataItem.note}</p>
        </>
      ) : (
        <CustomLoader width={100} height={100} />
      )}
    </div>
  );
};

export default ItemCommonInfo;
