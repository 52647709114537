import './AuctionRefusalReason.scss';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../assets/img/close.svg';
import {
  setRefusalOrderId,
  setIdItemsAuctionForRmove,
} from '../../store/rootState';
import apiV2Wizard from '../../utils/api.v2.wizard';
import { useFormik } from 'formik';
import CustomRadio from '../CustomRadio/CustomRadio';
import { checkTokenExpiresDate } from '../../utils/utils';

const REASONS = [
  {
    id: 1,
    title: 'Нет времени',
    value: 'no_time',
    checked: true,
  },
  {
    id: 2,
    title: 'Не знаком с темой',
    value: 'dont_undestand',
    checked: false,
  },
  {
    id: 3,
    title: 'Маленький гонорар',
    value: 'not_enough_money',
    checked: false,
  },
  {
    id: 4,
    title: 'Другое',
    value: 'another',
    checked: false,
  },
];

const AuctionRefusalReason = ({ createdRef }: any) => {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.root.token);
  const tokenExpires = useSelector((state: any) => state.root.tokenExpires);
  const orderId = useSelector((state: any) => state.root.refusalOrderId);
  const [sendStatus, setSendStatus] = useState<any>(null);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const ref = useRef<any>(null);

  const sendReasonData = (data: any) => {
    if (!checkTokenExpiresDate(tokenExpires)) {
      window.opener.location.reload();
      return;
    }
    setIsDisabledButton(true);
    apiV2Wizard
      .orderEventHide(token, data, orderId)
      .then((data) => {
        setIsDisabledButton(false);
        setSendStatus(true);
        setTimeout(() => {
          // обнуляю инфу по номеру заказа при закрытии
          dispatch(setIdItemsAuctionForRmove(orderId));
          dispatch(setRefusalOrderId({ refusalOrderId: null }));
          ref.current?.closest('.modal-wrapper').classList.remove('open');
          document.querySelector('body')!.classList.remove('modal');
        }, 1000);
      })
      .catch((error) => {
        if (error.status === 401) {
          window.opener.location.reload();
        }
        setIsDisabledButton(false);
        setSendStatus(false);
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      reason: String(REASONS[0].id),
      addInfo: '',
      id: orderId,
    },
    onSubmit: (values) => {
      let dataToSend = Object.assign({}, values);
      // Пересобираю
      dataToSend.reason =
        REASONS.find((item) => item.id === Number(values.reason))?.title +
        ' ' +
        values.addInfo;
      sendReasonData(dataToSend);
    },
  });

  return (
    <div className="auction-refusal-reason" ref={ref}>
      <button
        type="button"
        aria-label="Закрыть"
        className="auction-refusal-reason__close"
        onClick={(e: any) => {
          // обнуляю инфу по номеру заказа при закрытии
          dispatch(setRefusalOrderId({ refusalOrderId: null }));
          e.target.closest('.modal-wrapper').classList.remove('open');
          document.querySelector('body')!.classList.remove('modal');
        }}
      >
        <CloseIcon />
      </button>
      {sendStatus === null ? (
        <>
          <p className="auction-refusal-reason__name-main">
            Укажите причину отказа
          </p>
          <form
            onSubmit={formik.handleSubmit}
            className="auction-refusal-reason__wrapper"
          >
            <ul className="auction-refusal-reason__list">
              {REASONS && REASONS.length
                ? REASONS.map((item: any, index: any) => {
                    return (
                      <li className="auction-refusal-reason__item" key={index}>
                        <CustomRadio
                          id={item.id}
                          value={item.value}
                          title={item.title}
                          checked={item.checked}
                          onChange={(e: any) => {
                            formik.setFieldValue('reason', String(e.target.id));
                          }}
                          customClass={undefined}
                        />
                      </li>
                    );
                  })
                : null}
            </ul>
            <div className="auction-refusal-reason__textarea-wrapper">
              <textarea
                defaultValue={formik.values.addInfo}
                className="auction-refusal-reason__textarea"
                //Made for autosize textarea
                onInput={(e: any) =>
                  (e.target.parentNode.dataset.replicatedValue = e.target.value)
                }
                //
                onChange={(e: any) => {
                  formik.setFieldValue('addInfo', e.target.value);
                }}
              ></textarea>
            </div>
            <div className="auction-refusal-reason__buttons-wrapper">
              <button
                type="submit"
                className="auction-refusal-reason__button-submit"
                disabled={isDisabledButton}
              >
                Отказаться
              </button>
              <button
                type="button"
                className="auction-refusal-reason__button-cancel"
                onClick={() => {
                  dispatch(setRefusalOrderId({ refusalOrderId: null }));
                  ref.current
                    .closest('.modal-wrapper')
                    .classList.remove('open');
                  document.querySelector('body')!.classList.remove('modal');
                }}
              >
                Отмена
              </button>
            </div>
          </form>
        </>
      ) : sendStatus === true ? (
        <div className="auction-refusal-reason__result">
          Вы отказались от данного заказа!
        </div>
      ) : sendStatus === false ? (
        <div className="auction-refusal-reason__result">
          Ошибка! Попробуйте снова
        </div>
      ) : null}
    </div>
  );
};

export default AuctionRefusalReason;
