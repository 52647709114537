import './AuctionProposeOrder.scss';
import { useState } from 'react';
import CustomLoader from '../CustomLoader/CustomLoader';
import { useDispatch, useSelector } from 'react-redux';
import { setProposeOrderData, setProposeOrderId } from '../../store/rootState';
import { ReactComponent as CloseIcon } from '../../assets/img/close.svg';
import { ReactComponent as Ruble } from '../../assets/img/ruble.svg';
import { useFormik } from 'formik';
import apiV2Wizard from '../../utils/api.v2.wizard';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import { removeSpaces, checkTokenExpiresDate } from '../../utils/utils';
import { toast } from 'react-toastify';
import ProposeHelper from './ProposeHelper/ProposeHelper';
import ItemCommonInfo from '../ItemCommonInfo/ItemCommonInfo';
import * as Yup from 'yup';

const dateToString = (date: Date) => {
  if (date) {
    let dateYears = date.getFullYear();
    let dateMonth =
      date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    let dateDays = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    let dateHour =
      date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    let dateMinutes =
      date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    return `${dateYears}-${dateMonth}-${dateDays} ${dateHour}:${dateMinutes}`;
  }
  return '';
};

const AuctionProposeOrder = ({ createdRef }: any) => {
  const dispatch = useDispatch();
  const [buttonStatus, setButtonStatus] = useState<any>(false);
  const [proposalError, setProposalError] = useState<any>(null);
  const token = useSelector((state: any) => state.root.token);
  const tokenExpires = useSelector((state: any) => state.root.tokenExpires);
  const orderId = useSelector((state: any) => state.root.proposeOrderId);
  const orderData = useSelector((state: any) => state.root.proposeOrderData);
  const splitedDate = orderData?.deadline
    ? orderData?.deadline.split(' ')
    : null;
  let parsedDate;
  let initDatePickerValue;
  if (splitedDate) {
    parsedDate =
      splitedDate[0].split('.')[2] +
      '-' +
      splitedDate[0].split('.')[1] +
      '-' +
      splitedDate[0].split('.')[0] +
      'T' +
      splitedDate[1] +
      ':00';
    initDatePickerValue =
      new Date(parsedDate).getTime() > new Date().getTime()
        ? new Date(parsedDate)
        : new Date();
  }

  const ValidationScheme = Yup.object().shape({
    price: Yup.string(),
    deadline: Yup.date().min(
      new Date(new Date().setHours(new Date().getHours() + 1))
    ),
  });

  const formik = useFormik({
    initialValues: {
      price: orderData?.price
        ? orderData.price
            .toString()
            .replace(/ /g, '')
            .replace(/\D/g, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        : '0',
      deadline: initDatePickerValue,
    },
    validationSchema: ValidationScheme,
    onSubmit: (values) => {
      if (!checkTokenExpiresDate(tokenExpires)) {
        window.opener.location.reload();
        return;
      }
      setButtonStatus('loading');
      const dataToSend = {
        price: Number(removeSpaces(values.price)),
        deadline: values?.deadline
          ? dateToString(values?.deadline)
          : dateToString(new Date()),
      };

      apiV2Wizard
        .orderEventSet(token, dataToSend, orderId)
        .then((data) => {
          setButtonStatus('success');
          toast.success('Ваша ставка принята!', {
            position: 'bottom-right',
          });
          dispatch(setProposeOrderId({ proposeOrderId: null }));
          let copyedData = { ...orderData };
          copyedData.price = Number(removeSpaces(values.price));
          copyedData.authorsDate = dataToSend.deadline;
          dispatch(setProposeOrderData(copyedData));
          document.querySelector('body')!.classList.remove('modal');
        })
        .catch((error) => {
          const errorData = error.response.data;
          setButtonStatus('error');
          console.log(errorData);
          if (
            errorData &&
            errorData?.errors &&
            errorData?.errors?.messages &&
            errorData?.errors?.messages?.length
          ) {
            setProposalError(errorData.errors.messages[0]);
          }
          if (error.status === 401) {
            window.opener.location.reload();
          }
          console.log(error);
          toast.error('Ошибка отправки!', {
            position: 'bottom-right',
          });
        });
    },
  });

  return (
    <div className="auction-propose-order">
      <p className="auction-propose-order__name-main">
        <span className="item-preview__subject">{orderData?.course}</span>
        {': '}
        <span className="item-preview__theme">{orderData?.topic}</span>
      </p>
      <button
        type="button"
        aria-label="Закрыть"
        className="auction-propose-order__close"
        onClick={(e: any) => {
          // обнуляю инфу по номеру заказа при закрытии
          dispatch(setProposeOrderId({ proposeOrderId: null }));
          dispatch(setProposeOrderData(null));
          e.target.closest('.modal-wrapper').classList.remove('open');
          document.querySelector('body')!.classList.remove('modal');
        }}
      >
        <CloseIcon />
      </button>
      <div className="auction-propose-order__wrapper">
        {/* <p className="auction-propose-order__list-name">
          Прошлые ставки по заказу:
        </p>
        <ul className="auction-propose-order__list-previous-propose">
          <li className="auction-propose-order__item-previous-propose">
            <span className="auction-propose-order__date-previous-propose">
              29.01.2024 15:01
            </span>
            <span className="auction-propose-order__item-separator">-</span>
            <span className="auction-propose-order__sum-previous-propose">
              1 000 руб.
            </span>
          </li>
          <li className="auction-propose-order__item-previous-propose">
            <span className="auction-propose-order__date-previous-propose">
              29.01.2024 16:01
            </span>
            <span className="auction-propose-order__item-separator">-</span>
            <span className="auction-propose-order__sum-previous-propose">
              900 руб.
            </span>
          </li>
          <li className="auction-propose-order__item-previous-propose">
            <span className="auction-propose-order__date-previous-propose">
              29.01.2024 19:01
            </span>
            <span className="auction-propose-order__item-separator">-</span>
            <span className="auction-propose-order__sum-previous-propose">
              700 руб.
            </span>
          </li>
        </ul> */}
        <form
          onSubmit={formik.handleSubmit}
          className="auction-propose-order__form"
        >
          <div className="auction-propose-order__input-label-wrapper form-input-label-wrapper">
            <div className="auction-propose-order__input-button-wrapper">
              <label
                htmlFor="price"
                className="auction-propose-order__label form-label"
              >
                Ставка
                <div
                  className={`auction-propose-order__input-style-wrapper ${
                    formik.values.price
                      ? 'auction-propose-order__input-style-wrapper--active'
                      : ''
                  }`}
                >
                  {orderData?.price &&
                  orderData?.price > 100 &&
                  orderData?.price <
                    Number(removeSpaces(formik.values.price)) ? (
                    <span className="auction-propose-order__help-info">
                      Новая ставка выше предыдущей, вы уверены?
                    </span>
                  ) : null}
                  <input
                    value={formik.values.price}
                    className="auction-propose-order__input form-input"
                    type="text"
                    name="price"
                    id="price"
                    placeholder="Введите размер ставки"
                    onChange={(e) => {
                      let value = e.target.value === '0' ? '' : e.target.value;
                      let summ = value
                        .toString()
                        .replace(/ /g, '')
                        .replace(/\D/g, '')
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

                      formik.setFieldValue('price', summ);
                    }}
                  />
                  <Ruble className="auction-propose-order__input-svg" />
                </div>
              </label>
              <label
                htmlFor="deadline"
                className={
                  formik.errors.deadline
                    ? 'auction-propose-order__label form-label form-label--error'
                    : 'auction-propose-order__label form-label'
                }
              >
                Срок до:
                <div
                  className={`auction-propose-order__input-style-wrapper ${
                    formik.values.deadline
                      ? 'auction-propose-order__input-style-wrapper--active'
                      : ''
                  }`}
                >
                  {formik.errors.deadline ? (
                    <span className="auction-propose-order__error-info">
                      {`Срок должен быть больше ${new Date(
                        new Date().setHours(new Date().getHours() + 1)
                      ).toLocaleString('ru', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        timeZone: 'UTC',
                      })}`}
                    </span>
                  ) : null}
                  <CustomDatePicker
                    dateChanger={undefined}
                    filteredData={undefined}
                    dual={false}
                    range={false}
                    onChange={(value: any) => {
                      formik.setFieldValue('deadline', value);
                    }}
                    value={formik.values.deadline}
                  />
                </div>
              </label>
            </div>
            <ProposeHelper
              highest_bet={orderData?.highest_bet ? orderData.highest_bet : 0}
              setPrice={Number(removeSpaces(formik.values.price))}
            />
            <button
              type="submit"
              aria-label="Сделать ставку"
              className="auction-propose-order__submit-button"
              disabled={
                removeSpaces(formik.values.price) <= 0 ||
                buttonStatus === 'loading'
                  ? true
                  : false
              }
            >
              Сделать ставку
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AuctionProposeOrder;
