import './Main.scss';
import { Outlet } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import CustomLoader from '../CustomLoader/CustomLoader';

const Sidebar = lazy(() => import('../Sidebar/Sidebar'));

const Main = ({ sidebarState, handleOpenerSidebar, isIframe }: any) => {
  return (
    <main className="main">
      {isIframe ? (
        <Suspense
          fallback={<CustomLoader width={100} height={100}></CustomLoader>}
        >
          <Sidebar
            sidebarState={sidebarState}
            handleOpenerSidebar={handleOpenerSidebar}
          />
        </Suspense>
      ) : null}
      <section className="main__content-wrapper">
        {isIframe ? <Breadcrumbs /> : null}
        <Outlet />
      </section>
    </main>
  );
};

export default Main;
