import './ItemFiles.scss';
import { useLoaderData } from 'react-router-dom';
import { ReactComponent as HalfArrowIcon } from '../../assets/img/halfArrow.svg';
import { ReactComponent as FileThirdIcon } from '../../assets/img/filesThird.svg';
import { ReactComponent as LoadIcon } from '../../assets/img/load.svg';
import { ReactComponent as DocIcon } from '../../assets/img/docIcon.svg';
import { ReactComponent as JpgIcon } from '../../assets/img/jpgIcon.svg';
import { ReactComponent as PdfIcon } from '../../assets/img/pdfIcon.svg';
import { ReactComponent as EyeSecondIcon } from '../../assets/img/eyeSec.svg';
import { useEffect, useState } from 'react';
import CustomLoader from '../CustomLoader/CustomLoader';
import { COUNT_MONTH } from '../../utilsData';
import { loadDealById } from '../../utils/utilsApi';
import { token } from '../../mock';
import { nanoid } from 'nanoid';

export async function loader(loaderData: { params: any }) {
  return loaderData.params.id;
}
// Преварщает числовую дату в читаему типа "12 сентября в 09:55"
const setVisualData = (values: number) => {
  let newDate = new Date(values * 1000);
  return (
    (newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()) +
    ' ' +
    COUNT_MONTH[newDate.getMonth()] +
    /* ' ' +
    newDate.getFullYear() + */
    ' в ' +
    (newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours()) +
    ':' +
    (newDate.getMinutes() < 10
      ? '0' + newDate.getMinutes()
      : newDate.getMinutes())
  );
};

const ItemFiles = ({ customId, type }: any) => {
  const [dataItem, setDataItem] = useState<any>([]);

  const id: any = customId ? customId : useLoaderData() ? useLoaderData() : 1;

  const loadedByIdResultFunc = (value: any) => {
    setDataItem(value.data.data.entry.files);
  };

  useEffect(() => {
    id[0] === '№'
      ? loadDealById(
          token,
          Number(id.slice(1, id.length)),
          loadedByIdResultFunc
        )
      : loadDealById(token, Number(id), loadedByIdResultFunc);
  }, []);

  const [maxItems, setMaxItems] = useState(3);
  const [smallList, setSmallList] = useState([]);

  useEffect(() => {
    if (dataItem) {
      setSmallList(dataItem.slice(0, maxItems));
    }
  }, [dataItem]);

  useEffect(() => {
    if (dataItem) {
      setSmallList(dataItem.slice(0, maxItems));
    }
  }, [maxItems]);

  return (
    <div className={type ? `item-files item-files--${type}` : 'item-files'}>
      <ul className="item-files__list">
        {dataItem.length ? (
          <>
            {smallList.map((item: any, index: number) => (
              <li className="item-files__item" key={nanoid()}>
                <div className="item-files__link">
                  <a href="#" download className="item-files__link-wrapper">
                    {item.ext === 'doc' ||
                    item.ext === 'zip' ||
                    item.ext === 'docx' ? (
                      <DocIcon />
                    ) : item.type === 'jpg' ? (
                      <JpgIcon />
                    ) : item.type === 'pdf' ? (
                      <PdfIcon />
                    ) : (
                      ''
                    )}
                    <div className="item-files__info">
                      <p className="item-files__name">{item.name}</p>
                      <div className="item-files__dop-info">
                        <span>{item.size}</span>
                        <LoadIcon />
                        <span>{setVisualData(Number(item.date))}</span>
                      </div>
                    </div>
                    {item.description ? (
                      <p className="item-files__description">
                        {item.description}
                      </p>
                    ) : (
                      ''
                    )}
                  </a>
                  <div className="item-files__links-icon">
                    <a className="item-files__link-add item-files__link-add--eye">
                      <EyeSecondIcon />
                    </a>
                    <a
                      href="#"
                      className="item-files__link-add item-files__link-add--load"
                      download
                    >
                      <LoadIcon />
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </>
        ) : (
          <CustomLoader width={100} height={100} />
        )}
      </ul>
      <div className="item-files__footer">
        {maxItems < dataItem.length ? (
          <button
            type="button"
            className="item-files__show-more"
            onClick={() => setMaxItems(dataItem.length)}
          >
            <span>Показать все</span>
            <HalfArrowIcon />
          </button>
        ) : (
          ''
        )}
        <a href="#" className="item-files__files-counts-size" download>
          {dataItem.length ? dataItem.length + ' файлов (20,86 Мб)' : ''}
        </a>
        <a href="#" className="item-files__load-all-files" download>
          <span>Скачать одним архивом</span>
          <FileThirdIcon />
        </a>
      </div>
    </div>
  );
};

export default ItemFiles;
