import './CustomSelect.scss';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import styled from '@emotion/styled';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { ReactComponent as HalfArrowIcon } from '../../assets/img/halfArrow.svg';

const CustomMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-family: 'Inter', 'Arial', sans-serif;
  }
`;

const StyledSelect = styled(Select)`
  &.MuiInputBase-root {
    width: 100%;

    &:hover {
      & .MuiOutlinedInput-notchedOutline {
        border: solid calc(2 / 1680 * 100vw) var(--main-color-css);

        @media (max-width: 1919px) {
          border: solid 2px var(--main-color-css);
        }
      }
    }

    & svg {
      pointer-events: none;
      position: absolute;
      top: 50%;
      right: 19px;
      transform: translate(0, -50%);
      color: var(--bg-main-color-css);
      transition: transform 0.3s ease;

      @media (max-width: 1919px) {
        right: calc(19 / 1680 * 100vw);
      }
    }

    &.Mui-focused {
      & svg {
        transform: translate(0, -50%) rotate(180deg);
      }
    }
  }

  .MuiSelect-select {
    background: var(--color-light-gray-css);
    border-radius: calc(11 / 1680 * 100vw);
    padding: calc(13 / 1680 * 100vw) calc(32 / 1680 * 100vw)
      calc(13 / 1680 * 100vw) calc(19 / 1680 * 100vw);
    font-family: inherit;
    font-weight: inherit;
    line-height: 1.25rem;
    color: var(--bg-main-color-css);

    @media (max-width: 1919px) {
      border-radius: 11px;
      padding: 13px 32px 13px 19px;
      line-height: 19px;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: solid calc(2 / 1680 * 100vw) transparent;
    border-radius: calc(11 / 1680 * 100vw);
    transition: border 0.3s ease;
    top: 0;

    @media (max-width: 1919px) {
      border: solid 2px transparent;
      border-radius: 11px;
    }
  }

  legend {
    display: none;
  }
`;

const CustomLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    font-family: inherit;
    font-weight: inherit;
    line-height: 1.25rem;
    color: var(--bg-main-color-css);

    @media (max-width: 1919px) {
      line-height: 1.25rem;
    }

    &.Mui-focused {
      display: none;
    }
  }

  &.MuiFormLabel-filled {
    display: none;
  }
`;

const CustomSelect = ({
  name,
  idName,
  value,
  initialValue,
  values,
  addClass,
  onChange,
  required,
}: {
  name: string | undefined;
  idName: string | undefined;
  value: any;
  initialValue: any;
  values: any;
  addClass: any;
  onChange: any;
  required: any;
}) => {
  return (
    <div className={addClass ? 'select ' + addClass : 'select'}>
      {name ? (
        <p
          className={
            required
              ? 'select__name form-label form-label--required'
              : 'select__name form-label'
          }
        >
          {name}
        </p>
      ) : null}
      <FormControl fullWidth>
        <CustomLabel id={`demo-simple-select-label-first + ${idName}`}>
          {initialValue ? initialValue : 'Пустое'}
        </CustomLabel>
        <StyledSelect
          name={idName ? idName : '1'}
          labelId={`demo-simple-select-label + ${idName}`}
          id={idName ? idName : '1'}
          value={value ? value : 10}
          label={initialValue ? initialValue : 'Пустое'}
          onChange={onChange}
          IconComponent={() => <HalfArrowIcon />}
        >
          {values && values.length
            ? values.map((item: any, index: number) => (
                <CustomMenuItem value={item.value} key={index}>
                  {item.title}
                </CustomMenuItem>
              ))
            : [
                <CustomMenuItem value={10} key={1}>
                  Ten
                </CustomMenuItem>,
                <CustomMenuItem value={20} key={2}>
                  Twenty
                </CustomMenuItem>,
                <CustomMenuItem value={30} key={3}>
                  Thirty
                </CustomMenuItem>,
              ]}
        </StyledSelect>
      </FormControl>
    </div>
  );
};

export default CustomSelect;
