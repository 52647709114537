import './CustomDatePicker.scss';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { useState, forwardRef } from 'react';
import { ReactComponent as ArrowIcon } from '../../assets/img/arrow.svg';
import { ReactComponent as CalendarIcon } from '../../assets/img/calendar.svg';
import { ReactComponent as CalendarIconCheck } from '../../assets/img/CalendarCheck.svg';

const CustomDatePicker = ({
  dateChanger,
  filteredData,
  dual,
  range,
  onChange,
  value,
}: any) => {
  const dateNow = new Date();

  const [dateRange, setDateRange] = useState(
    filteredData
      ? filteredData
      : [
          dateNow.setMonth(dateNow.getMonth() - 1),
          dateNow.setMonth(dateNow.getMonth() + 1),
        ]
  );
  const [startDate, endDate] = dateRange;
  const [singleDate, setSingleDate] = useState<any>(value ? value : new Date());

  const [startDataSecond, setStartDateSecond] = useState(
    new Date(dateRange[0])
  );
  const [endDateSecond, setEndDateSecond] = useState(new Date(dateRange[1]));

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="custom-input-datepicker" onClick={onClick} ref={ref}>
      <span className="custom-input-datepicker__value">
        {value.split('-')[0]}
      </span>
      <ArrowIcon className="custom-input-datepicker__arrow" />
      <span className="custom-input-datepicker__value">
        {value.split('-')[1]}
      </span>

      <CalendarIcon className="custom-input-datepicker__calendar" />
    </div>
  ));

  const CustomSingleDate = forwardRef(({ value, onClick }, ref) => (
    <div
      className="custom-input-datepicker  custom-input-datepicker--single"
      onClick={onClick}
      ref={ref}
    >
      <span className="custom-input-datepicker__value">{value}</span>
      <CalendarIconCheck className="custom-input-datepicker__calendar" />
    </div>
  ));

  return (
    <>
      {dual ? (
        <div className="custom-input-datepicker custom-input-datepicker--dual">
          <DatePicker
            selected={startDataSecond}
            onChange={(date: Date) => {
              let newArr = [date, endDateSecond];
              dateChanger(newArr);
              setStartDateSecond(date);
            }}
            selectsStart
            startDate={startDataSecond}
            endDate={endDateSecond}
            dateFormat="dd.MM.yyyy"
            showYearDropdown
            showMonthDropdown
          />
          <ArrowIcon className="custom-input-datepicker__arrow" />
          <DatePicker
            selected={endDateSecond}
            onChange={(date: Date) => {
              let newArr = [startDataSecond, date];
              dateChanger(newArr);
              setEndDateSecond(date);
            }}
            selectsEnd
            startDate={startDataSecond}
            endDate={endDateSecond}
            minDate={startDataSecond}
            dateFormat="dd.MM.yyyy"
            showYearDropdown
            showMonthDropdown
          />
          <CalendarIcon className="custom-input-datepicker__calendar" />
        </div>
      ) : range === false ? (
        <DatePicker
          locale={ru}
          dayClassName={() => 'react-datepicker__day--single-time'}
          selected={singleDate}
          minDate={new Date(new Date().setHours(new Date().getHours() + 1))}
          startDate={singleDate}
          dateFormat="dd.MM.yy HH:mm"
          showYearDropdown
          showMonthDropdown
          showTimeSelect
          onChange={(update) => {
            if (dateChanger) {
              dateChanger(update);
            }
            setSingleDate(update);
            onChange(update);
          }}
          timeFormat="HH:mm"
          timeIntervals={30}
          timeCaption="Время"
          customInput={<CustomSingleDate />}
        />
      ) : (
        <DatePicker
          locale={ru}
          selectsRange={true}
          startDate={startDate}
          dateFormat="dd.MM.yyyy"
          endDate={endDate}
          showYearDropdown
          showMonthDropdown
          onChange={(update) => {
            if (dateChanger) {
              dateChanger(update);
            }
            setDateRange(update);
          }}
          customInput={<CustomInput />}
        />
      )}
    </>
  );
};

export default CustomDatePicker;
