import axios from 'axios';

const loadDealById = async (token, id, func) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const formdata = new FormData();
  formdata.append('order_id', id);

  await axios
    .post('https://wizard24.ru/crm/getOrderInfo', formdata, config)
    .then((response) => func(response))
    .catch((error) => console.log('error', error));
};

const loadDealsByType = async (token, status, func, page) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const params = new FormData();
  params.append('status', status);
  params.append('page', page ? page : 1);

  await axios
    .post('https://wizard24.ru/crm/getOrders', params, config)
    .then((response) => func(response))
    .catch((error) => console.log('error', error));
};

const loadDealsCountInitData = async (token, func) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  await axios
    .get('https://wizard24.ru/crm/initData', config)
    .then((response) => func(response))
    .catch((error) => console.log(error));
};

const loadProfileInitData = async (token, func) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  await axios
    .get('https://wizard24.ru/crm/userInfo', config)
    .then((response) => func(response))
    .catch((error) => console.log(error));
};
//date в формате '2017-01-01 00:00:00'
const loadFinancePaidData = async (token, dateStart, dateEnd, func, id) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const params = new FormData();
  params.append('filter[date_start]', dateStart);
  params.append('filter[date_end]', dateEnd);
  if (id) {
    params.append('filter[order_id]', id);
  }

  await axios
    .post('https://wizard24.ru/crm/finances/paid', params, config)
    .then((response) => func(response))
    .catch((error) => console.log(error));
};
//date в формате '2017-01-01 00:00:00'
const loadFinanceData = async (token, dateStart, dateEnd, id, status, func) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const params = new FormData();
  params.append('filter[date_start]', dateStart);
  params.append('filter[date_end]', dateEnd);
  params.append('filter[order_id]', id);
  params.append('filter[status]', status);

  await axios
    .post('https://wizard24.ru/crm/finances', params, config)
    .then((response) => func(response))
    .catch((error) => console.log(error));
};

export {
  loadDealById,
  loadDealsByType,
  loadDealsCountInitData,
  loadProfileInitData,
  loadFinancePaidData,
  loadFinanceData,
};
