import './StartPage.scss';
import { useEffect, useState } from 'react';
import CustomLoader from '../../components/CustomLoader/CustomLoader';

const StartPage = () => {
  const [loadingData, setLoadigData] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoadigData(false), 2000);
  }, []);
  return (
    <div className="start-page">
      {loadingData ? (
        <CustomLoader width={100} height={100} />
      ) : (
        <div className="start-page__wrapper">
          <p>Loaded data</p>
          <img src="https://api.lorem.space/image/movie?w=800&h=1000"></img>
        </div>
      )}
    </div>
  );
};

export default StartPage;
