import { createSlice } from '@reduxjs/toolkit';
import data from '../mock/orders.json';
/* import initData from '../mock/initialView.json'; */
import statuses from '../mock/v1__directory_orders_status.json';
const initialState = {
  data: data,
  initData: {},
  statuses: statuses,
  loadedFiles: [],
  files: [],
  dealsCounts: {},
  startData: {},
  secondLoadedFiles: [],
  dealsJob: { counts: 0, cost: 0 },
  token: null,
  tokenExpires: null,
  openOrderId: null,
  proposeOrderId: null,
  refusalOrderId: null,
  idItemsAuctionForRmove: null,
  proposeOrderData: null,
  approveUser: 1,
};

export const root = createSlice({
  name: 'root',
  initialState,
  reducers: {
    addFiles: (state, action) => {
      state.loadedFiles = action.payload;
    },
    addFilesMain: (state, action) => {
      return { ...state, secondLoadedFiles: action.payload };
    },
    addStartData: (state, action) => {
      return { ...state, startData: action.payload };
    },
    setDealsCounts: (state, action) => {
      return { ...state, dealsCounts: action.payload };
    },
    setDealsJob: (state, action) => {
      state.dealsJob.counts = action.payload.counts;
      state.dealsJob.cost = action.payload.cost;
    },
    setToken: (state, action) => {
      state.token = action.payload.token;
    },
    setTokenExpires: (state, action) => {
      state.tokenExpires = action.payload.expires;
    },
    setOpenOrderId: (state, action) => {
      state.openOrderId = action.payload.openOrderId;
    },
    setProposeOrderId: (state, action) => {
      state.proposeOrderId = action.payload.proposeOrderId;
    },
    setProposeOrderData: (state, action) => {
      state.proposeOrderData = action.payload;
    },
    setRefusalOrderId: (state, action) => {
      state.refusalOrderId = action.payload.refusalOrderId;
    },
    setIdItemsAuctionForRmove: (state, action) => {
      state.idItemsAuctionForRmove = action.payload;
    },
    setApproveUser: (state, action) => {
      state.approveUser = action.payload;
    },
  },
});

//export const rootState = (state) => state.root;

export const {
  addFiles,
  addFilesMain,
  addStartData,
  setDealsCounts,
  setDealsJob,
  setToken,
  setTokenExpires,
  setOpenOrderId,
  setProposeOrderId,
  setRefusalOrderId,
  setIdItemsAuctionForRmove,
  setProposeOrderData,
  setApproveUser,
} = root.actions;

export default root.reducer;
