import './CustomLoader.scss';
import { Dna } from 'react-loader-spinner';

const CustomLoader = ({ width, height }: { width: number; height: number }) => {
  return (
    <Dna
      visible={true}
      height={width}
      width={height}
      ariaLabel="dna-loading"
      wrapperStyle={{}}
      wrapperClass="dna-wrapper custom-spinner"
    />
  );
};

export default CustomLoader;
