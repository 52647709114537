import './ProposeHelper.scss';
import { ReactComponent as Molot } from '../../../assets/img/molot.svg';
import { useEffect, useState } from 'react';

const COEF_SALE = 0.95;
const COEF_CASH = 0.85;

const ProposeHelper = ({
  highest_bet,
  setPrice,
}: {
  highest_bet: number;
  setPrice: number;
}) => {
  const [priceToDecrease, setPriceToDecrease] = useState<any>(0);
  const [conditionType, setConditionType] = useState<any>(1);

  useEffect(() => {
    if (highest_bet > 0) {
      let priceDecreasePercent = Math.abs(
        Math.round(
          100 - ((highest_bet * COEF_SALE * COEF_CASH) / setPrice) * 100
        )
      );
      setPriceToDecrease(priceDecreasePercent > 99 ? 99 : priceDecreasePercent);
      /* setConditionType(setPrice <= highest_bet ? 1 : 2); */
    }
  }, [setPrice]);

  return (
    <div className="propose-helper__attention-info-wrapper">
      <Molot />
      <p
        className={
          conditionType !== 1
            ? 'propose-helper__attention-info propose-helper__attention-info--colored'
            : 'propose-helper__attention-info'
        }
      >
        {conditionType === 1 ? (
          `Сделав ставку, вы станете участником аукциона по данной работе`
        ) : (
          <>
            <span>Если вы снизите цену на</span>
            <span className="propose-helper__percents">
              {' '}
              {priceToDecrease}%
            </span>
            <span>, то вероятность того что вас выберут автором вырастет.</span>
          </>
        )}
      </p>
    </div>
  );
};

export default ProposeHelper;
