import './scss/index.scss';
import * as Sentry from '@sentry/react';
import React, { Suspense, lazy } from 'react';
import { ThemeProvider } from './themeProvider';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux/es/exports';
import { store } from './store/store';
import ErrorPage from './components/ErrorPage/ErrorPage';
import Root from './routes/root';
import StartPage from './routes/StartPage/StartPage';
/* import { loader as DealItemtLoader } from './routes/DealItem/DealItem';
import { loader as ItemCommonInfoLoader } from './components/ItemCommonInfo/ItemCommonInfo';
import { loader as ItemFilesLoader } from './components/ItemFiles/ItemFiles';
import { loader as ItemUploadedFilesLoader } from './components/ItemUploadedFiles/ItemUploadedFiles';
 */
const Auction = lazy(() => import('./routes/Auction/Auction'));

import CustomLoader from './components/CustomLoader/CustomLoader';

/* const AuthPage = lazy(() => import('./routes/Auth/Auth'));
const AuthForm = lazy(() => import('./routes/AuthForm/AuthForm'));
const PasswordRecovery = lazy(
  () => import('./routes/PasswordRecovery/PasswordRecovery')
);
const RegistrationPage = lazy(
  () => import('./routes/Registration/Registration')
);
const ProfilePage = lazy(() => import('./routes/ProfilePage/ProfilePage'));
const ProfileInfo = lazy(
  () => import('./routes/ProfilePage/ProfileInfo/ProfileInfo')
);
const Qualification = lazy(
  () => import('./routes/ProfilePage/Qualification/Qualification')
);
const AuctionSettings = lazy(
  () => import('./routes/ProfilePage/AuctionSettings/AuctionSettings')
);
const Requisites = lazy(() => import('./components/Requisites/Requisites'));
const NotificationSettings = lazy(
  () => import('./routes/ProfilePage/NotificationSettings/NotificationSettings')
);
const Deals = lazy(() => import('./routes/Deals/Deals'));
const Messages = lazy(() => import('./routes/Messages/Messages'));

const Stats = lazy(() => import('./routes/Stats/Stats'));
const StatsDeals = lazy(() => import('./components/StatsDeals/StatsDeals'));
const Rating = lazy(() => import('./components/Rating/Rating'));
const Finance = lazy(() => import('./routes/Finance/Finance'));
const GetMoney = lazy(() => import('./components/GetMoney/GetMoney'));
const HistoryOperation = lazy(
  () => import('./components/HistoryOperation/HistoryOperation')
);
const HistoryTran = lazy(() => import('./components/HistoryTran/HistoryTran'));
const HelpInfo = lazy(() => import('./routes/HelpInfo/HelpInfo'));
const Studing = lazy(() => import('./routes/Studing/Studing'));
const HelperMain = lazy(() => import('./routes/HelperMain/HelperMain'));
const NotificationPage = lazy(
  () => import('./routes/NotificationPage/NotificationPage')
);
const DealsOnDate = lazy(() => import('./routes/DealsOnDate/DealsOnDate'));
const DealItem = lazy(() => import('./routes/DealItem/DealItem'));
const ItemCommonInfo = lazy(
  () => import('./components/ItemCommonInfo/ItemCommonInfo')
);
const ItemFiles = lazy(() => import('./components/ItemFiles/ItemFiles'));
const ItemUploadedFiles = lazy(
  () => import('./components/ItemUploadedFiles/ItemUploadedFiles')
); */

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <StartPage /> },
      /*       {
        path: 'profile/',
        element: (
          <Suspense fallback={<CustomLoader width={100} height={100} />}>
            <ProfilePage />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<CustomLoader width={100} height={100} />}>
                <ProfileInfo />
              </Suspense>
            ),
          },
          {
            path: 'qualification/',
            element: (
              <Suspense fallback={<CustomLoader width={100} height={100} />}>
                <Qualification />
              </Suspense>
            ),
          },
          {
            path: 'auctionSettings/',
            element: (
              <Suspense fallback={<CustomLoader width={100} height={100} />}>
                <AuctionSettings
                  innerRefFirst={undefined}
                  innerRefFourth={undefined}
                  innerRefSecond={undefined}
                  innerRefThird={undefined}
                  styleType={undefined}
                  setValidFirst={undefined}
                  setGatherFifthData={undefined}
                  setGatherFourthData={undefined}
                  setGatherSixData={undefined}
                  setGatherThirdData={undefined}
                  setValidFourth={undefined}
                  setValidSecond={undefined}
                  setValidThird={undefined}
                />
              </Suspense>
            ),
          },
          {
            path: 'requisites/',
            element: (
              <Suspense fallback={<CustomLoader width={100} height={100} />}>
                <Requisites />
              </Suspense>
            ),
          },
          {
            path: 'notifications/',
            element: (
              <Suspense fallback={<CustomLoader width={100} height={100} />}>
                <NotificationSettings />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'deals/',
        element: (
          <Suspense fallback={<CustomLoader width={100} height={100} />}>
            <Deals />
          </Suspense>
        ),
      },
      {
        path: 'messages/',
        element: (
          <Suspense fallback={<CustomLoader width={100} height={100} />}>
            <Messages />
          </Suspense>
        ),
      }, */
      {
        path: 'auction/',
        element: (
          <Suspense fallback={<CustomLoader width={100} height={100} />}>
            <Auction />
          </Suspense>
        ),
      },
      /*       {
        path: 'stats/',
        element: (
          <Suspense fallback={<CustomLoader width={100} height={100} />}>
            <Stats />
          </Suspense>
        ),
        children: [
          {
            path: 'statsdeals/',
            element: (
              <Suspense fallback={<CustomLoader width={100} height={100} />}>
                <StatsDeals />
              </Suspense>
            ),
          },
          {
            path: 'rating/',
            element: (
              <Suspense fallback={<CustomLoader width={100} height={100} />}>
                <Rating />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'finance/',
        element: (
          <Suspense fallback={<CustomLoader width={100} height={100} />}>
            <Finance />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<CustomLoader width={100} height={100} />}>
                <GetMoney />
              </Suspense>
            ),
          },
          {
            path: 'historyOperation/',
            element: (
              <Suspense fallback={<CustomLoader width={100} height={100} />}>
                <HistoryOperation />
              </Suspense>
            ),
          },
          {
            path: 'historyTran/',
            element: (
              <Suspense fallback={<CustomLoader width={100} height={100} />}>
                <HistoryTran />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'helpInfo/',
        element: (
          <Suspense fallback={<CustomLoader width={100} height={100} />}>
            <HelpInfo />
          </Suspense>
        ),
      },
      {
        path: 'studing/',
        element: (
          <Suspense fallback={<CustomLoader width={100} height={100} />}>
            <Studing />
          </Suspense>
        ),
      },
      {
        path: 'question/',
        element: (
          <Suspense fallback={<CustomLoader width={100} height={100} />}>
            <HelperMain />
          </Suspense>
        ),
      },
      {
        path: 'notification/',
        element: (
          <Suspense fallback={<CustomLoader width={100} height={100} />}>
            <NotificationPage />
          </Suspense>
        ),
      },
      {
        path: 'deals/ondate/:date',
        element: (
          <Suspense fallback={<CustomLoader width={100} height={100} />}>
            <DealsOnDate />
          </Suspense>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: 'deals/:id',
        element: (
          <Suspense fallback={<CustomLoader width={100} height={100} />}>
            <DealItem />
          </Suspense>
        ),
        loader: DealItemtLoader,
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<CustomLoader width={100} height={100} />}>
                <ItemCommonInfo />
              </Suspense>
            ),
            loader: ItemCommonInfoLoader,
          },
          {
            path: 'files',
            element: (
              <Suspense fallback={<CustomLoader width={100} height={100} />}>
                <ItemFiles />
              </Suspense>
            ),
            loader: ItemFilesLoader,
          },
          {
            path: 'yourfiles',
            element: (
              <Suspense fallback={<CustomLoader width={100} height={100} />}>
                <ItemUploadedFiles />
              </Suspense>
            ),
            loader: ItemUploadedFilesLoader,
          },
        ],
      }, */
    ],
  },
  /*   {
    path: '/auth/',
    element: <AuthPage />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <AuthForm /> },
      {
        path: 'recovery/',
        element: <PasswordRecovery />,
      },
    ],
  },
  {
    path: '/registartion/',
    element: <RegistrationPage />,
    errorElement: <ErrorPage />,
  }, */
]);

Sentry.init({
  dsn: 'https://57cdc7bcdc6dba2d2a4758c0cbcb36b1@o4506858583883776.ingest.us.sentry.io/4506908064612352',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: .1, //  Capture 10% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://studservis-auction-client.vercel.app/',
    'https://auction.wizard24.ru/',
  ],
});

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
