import './CustomRadio.scss';

const CustomRadio = ({
  id,
  value,
  title,
  checked,
  onChange,
  customClass,
}: any) => {
  return (
    <>
      <input
        type="radio"
        id={id}
        value={value}
        defaultChecked={checked}
        onChange={onChange}
        name={'reason'}
        className={`custom-radio visibility-hidden ${
          customClass ? customClass : ''
        }`}
      />
      <label htmlFor={id}>{title}</label>
    </>
  );
};

export default CustomRadio;
