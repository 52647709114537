import './ChatFilesLoader.scss';
import { ReactComponent as CloseIcon } from '../../assets/img/close.svg';
import { ReactComponent as LoadIcon } from '../../assets/img/load.svg';
import { ReactComponent as DocIcon } from '../../assets/img/docIcon.svg';
import { ReactComponent as JpgIcon } from '../../assets/img/jpgIcon.svg';
import { ReactComponent as PdfIcon } from '../../assets/img/pdfIcon.svg';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { addFilesMain } from '../../store/rootState';
import CustomSelect from '../CustomSelect/CustomSelect';
import { useDispatch } from 'react-redux';

const ChatFilesLoader = (createdRef: any) => {
  const dispath = useDispatch();
  const data = useSelector((state: any) => state.root.loadedFiles);
  const [uploadedList, setUploadedList] = useState(data);

  useEffect(() => {
    setUploadedList(data);
  }, [data]);

  return (
    <div className="uploaded-files">
      <p className="uploaded-files__name-main">Прикрепить файлы к заказу</p>
      <button
        type="button"
        className="uploaded-files__close"
        onClick={(e: any) => {
          e.target.closest('.modal-wrapper').classList.remove('open');
          document.querySelector('body')!.classList.remove('modal');
        }}
      >
        <CloseIcon />
      </button>
      <button
        type="button"
        className="uploaded-files__close"
        onClick={(e: any) => {
          e.target.closest('.modal-wrapper').classList.remove('open');
          document.querySelector('body')!.classList.remove('modal');
        }}
      >
        <CloseIcon />
      </button>
      {uploadedList && uploadedList.length ? (
        <>
          <ul className="uploaded-files__list">
            <li className="uploaded-files__item">
              <span>Загруженные файлы:</span>
              <span>Выберите тип файла</span>
            </li>
            {uploadedList
              ? uploadedList.map((item: any, index: any) => (
                  <li className="uploaded-files__item" key={index}>
                    <div className="uploaded-files__item-info">
                      <a href="#" className="uploaded-files__link" download>
                        {item.type === 'doc' ? (
                          <DocIcon />
                        ) : item.type === 'image/jpeg' ||
                          item.type === 'image/svg+xml' ||
                          item.type === 'image/webp' ||
                          item.type === 'image/png' ? (
                          <JpgIcon />
                        ) : item.type === 'pdf' ? (
                          <PdfIcon />
                        ) : (
                          ''
                        )}
                        <div className="uploaded-files__info">
                          <p className="uploaded-files__name">{item.name}</p>
                          <div className="uploaded-files__dop-info">
                            <span>{item.size + 'b'}</span>
                            <LoadIcon />
                            <span>{item.date}</span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <CustomSelect
                      name={''}
                      value=""
                      values={[]}
                      initialValue="Не выбран"
                      idName={undefined}
                      addClass={undefined}
                      onChange={undefined}
                      required={undefined}
                    />
                  </li>
                ))
              : null}
          </ul>
          <textarea
            className="uploaded-files__textarea"
            placeholder="Место для текста"
          ></textarea>
          <button
            className="uploaded-files__load-button"
            onClick={() => {
              dispath(addFilesMain(uploadedList));
              if (createdRef.current) {
                createdRef.current.classList.remove('open');
                document.querySelector('body')!.classList.remove('modal');
              }
            }}
          >
            Отправить сообщение
          </button>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default ChatFilesLoader;
