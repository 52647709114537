import './AuctionOrderInfo.scss';
import CustomLoader from '../CustomLoader/CustomLoader';
import ItemCommonInfo from '../ItemCommonInfo/ItemCommonInfo';
import ItemFiles from '../ItemFiles/ItemFiles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setOpenOrderId } from '../../store/rootState';
import { ReactComponent as CloseIcon } from '../../assets/img/close.svg';

const AuctionOrderOnfo = ({ createdRef }: any) => {
  const dispatch = useDispatch();
  const orderId = useSelector((state: any) => state.root.openOrderId);
  const [activeTab, setActiveTab] = useState(1);
  const [orderData, setOrderData] = useState<any>(true);

  useEffect(() => {
    orderId ? setOrderData(true) : setOrderData(false);
  }, [orderId]);

  return (
    <div className="auction-order-info">
      {orderData && orderId ? (
        <>
          <p className="auction-order-info__name-main">Заказ №{orderId}</p>
          <button
            type="button"
            aria-label="Закрыть"
            className="auction-order-info__close"
            onClick={(e: any) => {
              console.log('cliclk');
              // обнуляю инфу по номеру заказа при закрытии
              dispatch(setOpenOrderId({ openOrderId: null }));
              e.target.closest('.modal-wrapper').classList.remove('open');
              document.querySelector('body')!.classList.remove('modal');
            }}
          >
            <CloseIcon />
          </button>
          <div className="auction-order-info__wrapper">
            <div className="auction-order-info__header">
              <ul className="auction-order-info__header-list">
                <li className="auction-order-info__header-item">
                  <button
                    type="button"
                    aria-label="Требования"
                    className={`auction-order-info__header-button ${
                      activeTab === 1
                        ? 'auction-order-info__header-button--active'
                        : ''
                    }`}
                    onClick={() => {
                      setActiveTab(1);
                      return;
                    }}
                  >
                    Требования к заказу
                  </button>
                </li>
                <li className="auction-order-info__header-item">
                  <button
                    type="button"
                    aria-label="Файл"
                    className={`auction-order-info__header-button ${
                      activeTab === 2
                        ? 'auction-order-info__header-button--active'
                        : ''
                    }`}
                    onClick={() => {
                      setActiveTab(2);
                      return;
                    }}
                  >
                    Файлы
                  </button>
                </li>
              </ul>
            </div>
            <div className="auction-order-info__body">
              {activeTab === 1 ? (
                <ItemCommonInfo customId={orderId} type="modal" />
              ) : (
                <ItemFiles customId={orderId} type="modal" />
              )}
            </div>
          </div>
        </>
      ) : (
        <CustomLoader width={100} height={100} />
      )}
    </div>
  );
};

export default AuctionOrderOnfo;
